import type { AxiosError } from "axios";
import { differenceInMilliseconds } from "date-fns";
import _ from "lodash";
import fp from "lodash/fp";
import { useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import type { ClientShop } from "src/services/bbp-backend/shop";
import { getShop } from "src/services/bbp-backend/shop";
import type { BlankosApiErrorResult } from "src/services/types";
import type { CartItem } from "./useCart";
import { useCart } from "./useCart";
import { useStoreId } from "./useStoreId";

export const useShop = () => {
  const storeId = useStoreId((s) => s.storeId);
  const [items, setItem] = useCart((s) => [s.items, s.setItem]);
  const queryClient = useQueryClient();

  const query = useQuery<ClientShop, AxiosError<BlankosApiErrorResult>>(
    ["shop", storeId],
    () => getShop(storeId),
    {
      staleTime: 1000 * 60 * 15,
      onSuccess: (data) => {
        const isInStore = (cartItem: CartItem) => {
          return _.some(data?.items, fp.matches({ itemId: cartItem.itemId }));
        };

        // remove cart items that aren't in the updated store
        _.forEach(items, (item) => {
          if (isInStore(item)) return;
          setItem(item.itemId, 0);
        });
      },
    },
  );

  // invalidate store when a new drop happens
  useEffect(() => {
    const nextLiveTimestamp = query.data?.nextLiveTimestamp;
    // abort if invalid
    if (_.isNil(nextLiveTimestamp)) return;
    const now = new Date();
    const nextLive = new Date(nextLiveTimestamp);
    const diff = differenceInMilliseconds(nextLive, now);

    const timeout = setTimeout(
      () => queryClient.invalidateQueries(["shop"], { fetching: false }), // do not invalidate queries that are in-progress fetching
      Math.max(diff, 0), // just in case the nextLive has passed but not propagated yet
    );

    return () => clearTimeout(timeout);
  }, [query.data?.nextLiveTimestamp, queryClient]);

  return query;
};
