import type { NextPageWithLayout } from "next";
import type { AppProps } from "next/app";
import React from "react";
import type { DehydratedState } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ThirdPartyScripts } from "src/components/ThirdPartyScripts";
import config from "src/config";
import KountScript from "src/lib/kount/Kount";
import AppProviders from "../components/AppProviders";
import BrowserLayout from "../layouts/BrowserLayout";
import "../styles/global.css";

type AppPropsWithLayout<P = Record<never, never>> = AppProps<P> & {
  Component: NextPageWithLayout<P>;
};

/** See https://stackoverflow.com/questions/64722812/what-typescript-type-should-nextjs-app-tsx-component-and-pageprops-be/64722865 for why pageprops is 'any'*/
export type AppPageProps = Record<string, unknown> & {
  dehydratedState?: DehydratedState;
};

const App: React.FC<AppPropsWithLayout<AppPageProps>> = ({
  Component,
  pageProps,
}) => {
  const Layout =
    typeof Component.getLayout === "function"
      ? Component.getLayout(pageProps)
      : BrowserLayout;

  return (
    <>
      <ThirdPartyScripts />
      <AppProviders {...pageProps}>
        <Layout>
          <Component {...pageProps} />
        </Layout>
        {config.enableDevtools && <ReactQueryDevtools initialIsOpen={false} />}
      </AppProviders>
      <KountScript />
    </>
  );
};

export default App;
