import type { ReduxAction } from "../../../types";
import type {
  SxSWBlanko,
  SxSWRedemptionResult,
  SxSWRedemptionStatus,
} from "./types";

const prefix = "@redemption/sxsw";

export const sxswRedemptionActions = {
  CHECK_CODE: `${prefix}/CHECK_CODE`,
  SAVE_CODE: `${prefix}/SAVE_CODE`,
  SET_STATUS: `${prefix}/SET_STATUS`,
  QUEUE_CLAIM: `${prefix}/QUEUE_CLAIM`,
  SET_REDEEMED: `${prefix}/SET_REDEEMED`,
  CLEAR_QUEUE: `${prefix}/CLEAR_QUEUE`,
  REDEEM_BLANKOS: `${prefix}/REDEEM_BLANKOS`,
  REDEEM_BLANKOS_RETRY: `${prefix}/REDEEM_BLANKOS_RETRY`,
  SET_REDEMPTION_RESULT: `${prefix}/SET_REDEMPTION_RESULT`,
};

export const checkCode = (code: string): ReduxAction<string> => ({
  type: sxswRedemptionActions.CHECK_CODE,
  payload: code,
});

export const saveCode = (code: string): ReduxAction<string> => ({
  type: sxswRedemptionActions.SAVE_CODE,
  payload: code,
});

export const setStatus = (
  status: SxSWRedemptionStatus,
): ReduxAction<SxSWRedemptionStatus> => ({
  type: sxswRedemptionActions.SET_STATUS,
  payload: status,
});

export const queueClaim = (claim: SxSWBlanko): ReduxAction<SxSWBlanko> => ({
  type: sxswRedemptionActions.QUEUE_CLAIM,
  payload: claim,
});

export const clearQueue = (): ReduxAction => ({
  type: sxswRedemptionActions.CLEAR_QUEUE,
});

export const setRedeemed = (
  redeemed: SxSWBlanko[],
): ReduxAction<SxSWBlanko[]> => ({
  type: sxswRedemptionActions.SET_REDEEMED,
  payload: redeemed,
});

export const redeemBlankos = (
  redemptionCodes: string[],
): ReduxAction<string[]> => ({
  type: sxswRedemptionActions.REDEEM_BLANKOS,
  payload: redemptionCodes,
});

export const redeemBlankosRetry = (
  redemptionCodes: string[],
): ReduxAction<string[]> => ({
  type: sxswRedemptionActions.REDEEM_BLANKOS_RETRY,
  payload: redemptionCodes,
});

export const setRedemptionResult = (
  result: SxSWRedemptionResult,
): ReduxAction<SxSWRedemptionResult> => ({
  type: sxswRedemptionActions.SET_REDEMPTION_RESULT,
  payload: result,
});
