import { combineReducers } from "redux";
import { fork } from "redux-saga/effects";
import { auth, authSagas } from "./auth";
import { sxswRedemption, sxswRedemptionSagas } from "./redemption/SxSW";
import { emailRedemption, emailRedemptionSagas } from "./redemption/email";

export const createRootReducer = () => {
  return combineReducers({
    auth,
    emailRedemption,
    sxswRedemption,
  });
};

export function* rootSaga() {
  yield fork(authSagas);
  yield fork(emailRedemptionSagas);
  yield fork(sxswRedemptionSagas);
}
