import React from "react";
import type { CloudflareImageProps } from "../lib/cloudflare";
import { transformImageSrc } from "../lib/cloudflare";

const Image: React.FC<React.ComponentProps<"img"> & CloudflareImageProps> = (
  props,
) => {
  const { src, width, height, format, quality, ...rest } = props;
  const imgSrc = React.useMemo(
    () =>
      transformImageSrc({
        src: src,
        width: width,
        height: height,
        format: format,
        quality: quality,
      }),
    [props],
  );

  return <img src={imgSrc} height={height} width={width} {...rest} />;
};

export default Image;
