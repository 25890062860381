export default interface SxSWRedemptionState {
  currentCode?: string;
  currentStatus?: SxSWRedemptionStatus;
  checkedCodes?: string[];
  queuedRedemptions?: SxSWBlanko[];
  retries: number;
  redemptions?: SxSWBlanko[];
  redemptionResult?: SxSWRedemptionResult;
}

export interface SxSWBlanko {
  redemptionCode?: string;
  redemptionStatus?: SxSWRedemptionStatus;
  thumbnail?: string;
  name?: string;
  editionNumber?: number;
  editionSize?: number;
}

export enum SxSWRedemptionStatus {
  Unchecked = "UNCHECKED",
  Available = "AVAILABLE",
  Redeemed = "REDEEMED",
  NotFound = "NOTFOUND",
  Error = "ERROR",
}

export enum SxSWRedemptionResult {
  Pending = "PENDING",
  Success = "SUCCESS",
  NothingToRedeem = "NOTHING_TO_REDEEM",
  CouldNotRedeem = "COULD_NOT_REDEEM",
  NotReady = "NOT_READY",
  Error = "ERROR",
}

export interface SxSWResponse {
  id: string;
  redemptionCode: string;
  redeemed: boolean;
  redemptionDate: Date;
  playerId: string;
  itemId: string;
  catalogName: string;
  gameInventoryId: string;
  imageSmall: string;
  tokenName: string;
  category: string;
  name: string;
  description: string;
  artist: string;
  serialNumber: number;
  maxSupply: number;
  dateCreated: Date;
}
