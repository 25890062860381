import {
  all,
  call,
  fork,
  put,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { keycloakInstance as keycloak } from "../../../providers/keycloak";
import { analytics } from "../../../services/analytics-api";
import { createAccount } from "../../../services/bbp-backend";
import type { ReduxAction, ResponseStatus } from "../../types";
import authActions, {
  createAccountFailure,
  createAccountSuccess,
} from "./actions";
import type { Registration, SetMythicalInfoType } from "./types";

// SAGAS

// TODO: fix whatever this is
// eslint-disable-next-line
function* setKeycloakSaga() {
  if (keycloak.authenticated) {
    const { preferred_username } = keycloak.idTokenParsed;
    analytics.setUser(keycloak.subject as any, preferred_username);
  }
}

function* reqLoginSaga() {
  analytics.snowplowTrack({
    id: analytics.Events.LOGIN_CLICKED,
  });

  yield call(keycloak.login);
}

function* reqLogoutSaga() {
  yield call(keycloak.logout);
}

function* createAccountSaga(registration: Registration): Generator<any> {
  const res: ResponseStatus<{ mythicalId: string; hash: string }> = (yield call(
    createAccount,
    registration,
  )) as any;

  res.success
    ? yield put(
        createAccountSuccess({
          mythicalId: res?.data?.mythicalId as any,
          mythicalIdHash: res?.data?.hash as any,
          mythicalUsername: registration.email,
        }),
      )
    : yield put(createAccountFailure(res));
}

// TODO: fix whatever this is
// eslint-disable-next-line
function* createAccountSuccessSaga(mythicalInfo: SetMythicalInfoType) {
  analytics.setUser(mythicalInfo.mythicalId, mythicalInfo.mythicalUsername);
  analytics.snowplowTrack({
    id: analytics.Events.SIGNUP_CREATED,
  });
}

// TODO: fix whatever this is
// eslint-disable-next-line
function* createAccountFailureSaga() {
  analytics.snowplowTrack({
    id: analytics.Events.SIGNUP_ERROR,
  });
}

// WATCHERS

function* watchSetKeycloakSaga() {
  yield takeLatest(authActions.SET_KEYCLOAK, setKeycloakSaga);
}

function* watchReqLoginSaga() {
  yield takeLatest(authActions.REQ_LOGIN, reqLoginSaga);
}

function* watchReqLogoutSaga() {
  yield takeLatest(authActions.REQ_LOGOUT, reqLogoutSaga);
}

function* watchCreateAccountSaga() {
  while (true) {
    const { payload }: ReduxAction<Registration> = yield take(
      authActions.CREATE_ACCOUNT,
    );
    yield call(createAccountSaga as any, payload);
  }
}

function* watchCreateAccountSuccessSaga() {
  while (true) {
    const { payload }: ReduxAction<SetMythicalInfoType> = yield take(
      authActions.CREATE_ACCOUNT_SUCCESS,
    );
    yield call(createAccountSuccessSaga as any, payload);
  }
}

function* watchCreateAccountFailureSaga() {
  yield takeEvery(authActions.CREATE_ACCOUNT_FAILURE, createAccountFailureSaga);
}

export default function* authenticationSagas(): Generator<any> {
  return yield all([
    fork(watchSetKeycloakSaga),
    fork(watchReqLoginSaga),
    fork(watchReqLogoutSaga),
    fork(watchCreateAccountSaga),
    fork(watchCreateAccountSuccessSaga),
    fork(watchCreateAccountFailureSaga),
  ]);
}
