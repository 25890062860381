import * as Sentry from "@sentry/react";
//#endregion
//#region Sentry
import config from "./config";
//#region LogRocket
const { enableLogRocket, logRocketProject, logRocketRelease } = config;

function initLogRocket() {
  if (enableLogRocket) {
    try {
      // necessary to prevent logrocket from loading outside of its environment for some reason.
      // TODO: try to handle this properly
      // eslint-disable-next-line
      const LogRocket = require("logrocket");
      // eslint-disable-next-line
      const setupLogRocketReact = require("logrocket-react");

      // LogRocket is only in lower environments so we don't need to sanitize much
      LogRocket.init(logRocketProject, {
        release: logRocketRelease,
        shouldCaptureIP: true,
        dom: {
          inputSanitizer: true,
        },
        console: {
          shouldAggregateConsoleErrors: true,
        },
      });
      setupLogRocketReact(LogRocket);

      LogRocket.getSessionURL(function _(sessionURL: any) {
        Sentry.configureScope(function _(scope) {
          scope.setExtra("sessionURL", sessionURL);
        });
      });
    } catch {
      // ignore
    }
  }
}
//#endregion

function initialize() {
  initLogRocket();
}

export default initialize;
