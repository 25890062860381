import React from "react";
import { BlankosFooter } from "@mythicalgames/blankos-ui";
import Image from "../components/Image";
import { analytics } from "../services/analytics-api";

const Footer = () => {
  return (
    <footer>
      <BlankosFooter
        rootUrl={new URL("https://www.blankos.com")}
        mythicalIcon={
          <Image
            src="/images/icons/mythical_m_logo.png"
            alt="Mythical icon"
            width={100}
            height={100}
          />
        }
        thirdKindIcon={
          <Image
            src="/images/icons/thirdkind_logo.png"
            alt="Third Kind Games icon"
            width={100}
            height={100}
          />
        }
        discordIcon={
          <Image
            src="/images/icons/discord_logo.png"
            alt="Discord icon"
            width={25}
            height={25}
          />
        }
        handleLauncherClick={() => {
          analytics.snowplowTrack({
            id: analytics.Events.DOWNLOAD_CTA_CLICKED,
          });
        }}
      ></BlankosFooter>
    </footer>
  );
};

export default Footer;
