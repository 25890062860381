import type { Theme } from "@material-ui/core";
import {
  Button,
  Link,
  makeStyles,
  Modal,
  NoSsr,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import CookiePreferencesModal from "./CookiePreferencesModal";
import { useCookiePreferences } from "./useCookiePreferences";

const useStyles = makeStyles((theme: Theme) => ({
  banner: {
    background: theme.palette.common.black,
    color: theme.palette.common.white,
    display: "flex",
    zIndex: 10000,
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      "& > p": {
        fontSize: "14px",
      },
    },
  },
  buttonWrapper: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
      justifyContent: "space-around",
    },
  },
  acceptButton: {
    background: theme.palette.common.white,
    color: "black",
    margin: theme.spacing(0, 1),
    padding: "10px 25px",
    fontSize: "16px",
    fontWeight: "bold",
    borderRadius: "0",
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.grey[200],
    },
  },
  settingsButton: {
    background: theme.palette.common.black,
    border: `2px solid ${theme.palette.common.white}`,
    color: theme.palette.common.white,
    margin: theme.spacing(0, 1),
    padding: "10px 25px",
    fontSize: "16px",
    fontWeight: "bold",
    borderRadius: "0",
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.grey[800],
    },
  },
  modalWrapper: {
    zIndex: 11000,
  },
}));

const CookieBanner = () => {
  const classes = useStyles();
  const { preferences, acceptAll } = useCookiePreferences();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onSettings = () => {
    setModalOpen(true);
  };

  return (
    <NoSsr>
      {!preferences.acknowledged && (
        <>
          <div className={classes.banner}>
            <Typography>
              This website uses cookies for analytics, functionality, improved
              site experience, and content and advertising measurement and
              personalization. By clicking “Accept”, you agree to the use of
              these cookies as further described in our{" "}
              <Link
                href="https://www.mythical.games/privacy"
                rel="noopener noreferrer"
                target="_blank"
              >
                Privacy Policy
              </Link>
              .
            </Typography>
            <div className={classes.buttonWrapper}>
              <Button
                onClick={acceptAll}
                variant="contained"
                className={classes.acceptButton}
              >
                Accept
              </Button>
              <Button
                onClick={onSettings}
                variant="contained"
                className={classes.settingsButton}
              >
                Settings
              </Button>
            </div>
          </div>
          <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            className={classes.modalWrapper}
          >
            <>
              <CookiePreferencesModal onClose={() => setModalOpen(false)} />
            </>
          </Modal>
        </>
      )}
    </NoSsr>
  );
};

export default CookieBanner;
