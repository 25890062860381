import axios from "axios";
import _, { merge } from "lodash";
import { getSession } from "next-auth/react";
import config from "../../config";

export const { apiUrl } = config;

// passes in the auth token when available
axios.interceptors.request.use(async (config) => {
  if (global.window === undefined) return config;
  const session = await getSession();

  const accessToken = session?.accessToken;
  if (!_.isString(accessToken)) return config;

  return merge(config, {
    headers: {
      Authorization: `Bearer ${session?.accessToken}`,
    },
  });
});

export default axios;
