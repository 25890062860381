import config from "../../config";
import {
  SxSWRedemptionResult,
  SxSWRedemptionStatus,
} from "../../store/modules/redemption/SxSW";
import type {
  SxSWBlanko,
  SxSWResponse,
} from "../../store/modules/redemption/SxSW/types";
import api from "../bbp-backend/client";
import { logger } from "../logger-api";

const { apiUrl } = config;

const getBaseApiUrl = (): string => {
  return apiUrl;
};

export const redeemSxswItems = async (
  redemptionCodes: string[],
): Promise<SxSWRedemptionResult> => {
  let responseStatus: number;

  try {
    const response = await api.put(
      `${getBaseApiUrl()}/client/redemption/sxsw`,
      redemptionCodes,
    );

    responseStatus = response.status;
  } catch (ex: any) {
    responseStatus = ex.response.status;

    if (![404, 409, 425].includes(responseStatus)) {
      logger.error(ex);
    }
  }

  switch (responseStatus) {
    case 200:
      return SxSWRedemptionResult.Success;
    case 404:
      return SxSWRedemptionResult.NothingToRedeem;
    case 409:
      return SxSWRedemptionResult.CouldNotRedeem;
    case 425:
      return SxSWRedemptionResult.NotReady;
    default:
      return SxSWRedemptionResult.Error;
  }
};

export const getSxswRedemptionByCode = async (
  redemptionCode: string,
): Promise<SxSWBlanko> => {
  // TODO: This is just to ease development. Remove when done.
  if (redemptionCode.length < 5) {
    const foo = fakeResponse(redemptionCode);
    return foo;
  }

  const blanko: SxSWBlanko = {};

  try {
    const response = await api.get(
      `${getBaseApiUrl()}/client/redemption/sxsw/${redemptionCode}`,
    );
    const blankoData: SxSWResponse = response.data;

    blanko.redemptionStatus = SxSWRedemptionStatus.Available;
    blanko.redemptionCode = redemptionCode;
    // TODO: API currently cannot return blanko name, when it can replace description with it
    blanko.name = blankoData.description; //blankoData.name;
    blanko.thumbnail = blankoData.imageSmall;
    blanko.editionNumber = blankoData.serialNumber;
    blanko.editionSize = blankoData.maxSupply;
  } catch (ex: any) {
    switch (ex.response.status) {
      case 404:
        blanko.redemptionStatus = SxSWRedemptionStatus.NotFound;
        break;

      case 409:
        blanko.redemptionStatus = SxSWRedemptionStatus.Redeemed;
        break;

      default:
        blanko.redemptionStatus = SxSWRedemptionStatus.Error;
        logger.error(ex);
        break;
    }
  }

  return blanko;
};

function fakeResponse(redemptionCode: string): SxSWBlanko {
  const response: SxSWBlanko = {};

  switch (redemptionCode) {
    case "1234":
      response.redemptionStatus = SxSWRedemptionStatus.Available;
      response.redemptionCode = "1234";
      response.editionNumber = 15;
      response.editionSize = 100;
      response.name = "fake blanko";
      response.thumbnail = "/fake/image.png";
      return response;

    case "1111":
      response.redemptionStatus = SxSWRedemptionStatus.Available;
      response.redemptionCode = "1111";
      response.editionNumber = 23;
      response.editionSize = 1000;
      response.name = "ersatz blanko";
      response.thumbnail = "/ersatz/image.png";
      return response;

    case "2":
      response.redemptionStatus = SxSWRedemptionStatus.Redeemed;
      return response;

    case "3":
      response.redemptionStatus = SxSWRedemptionStatus.NotFound;
      return response;

    case "4":
      response.redemptionStatus = SxSWRedemptionStatus.Error;
      return response;

    case "5":
    default:
      response.redemptionStatus = SxSWRedemptionStatus.Unchecked;
      return response;
  }
}

export const getRedemptionStatusByCode = async (
  redemptionCode: string,
): Promise<SxSWRedemptionStatus> => {
  switch (redemptionCode) {
    case "1":
      return SxSWRedemptionStatus.Available;
    case "2":
      return SxSWRedemptionStatus.Redeemed;
    case "3":
      return SxSWRedemptionStatus.NotFound;
    case "4":
      return SxSWRedemptionStatus.Error;
    case "5":
      return SxSWRedemptionStatus.Unchecked;
  }

  return SxSWRedemptionStatus.Available;
};
