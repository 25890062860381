import { useSelector } from "react-redux";
import { windowHelpers } from "../helpers/window-helpers";
import type StoreState from "../store";
import { useSession, signIn, signOut } from "next-auth/react";

/**
 * Provide authentication information to the client.
 * If we're not in a browser environment, it determines that no authentication information is available.
 * @todo If not in browser, this should probably return that it is initialized so pages render as non-blank.
 */
export function useAuth() {
  if (!windowHelpers.isInBrowser())
    return {
      initialized: false,
      isLoggedIn: false,
    };

  // TODO: these should not be called conditionally; the components that render them should be rendered conditionally based on react-no-ssr.
  // eslint-disable-next-line
  const { data: session, status } = useSession();

  // eslint-disable-next-line
  const xMythicalId = useSelector<StoreState, string | undefined>((store) =>
    store.auth.mythicalId && store.auth.mythicalIdHash
      ? `${store.auth.mythicalId}:${store.auth.mythicalIdHash}`
      : undefined,
  );

  return {
    initialized: status !== "loading",
    mythicalId: session?.user?.id,
    mythicalUsername: session?.user?.email,
    xMythicalId,
    isLoggedIn: status === "authenticated",
    login: () => signIn("keycloak"),
    logout: signOut,
  };
}
