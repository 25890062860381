import { all, call, delay, fork, put, select, take } from "redux-saga/effects";
import {
  getSxswRedemptionByCode,
  redeemSxswItems,
} from "../../../../services/redemption/sxsw-api";
import type StoreState from "../../../index";
import type { ReduxAction } from "../../../types";
import AuthState from "../../auth";
import {
  clearQueue,
  queueClaim,
  redeemBlankos,
  redeemBlankosRetry,
  setRedeemed,
  setRedemptionResult,
  setStatus,
  sxswRedemptionActions,
} from "./actions";
import type { SxSWBlanko } from "./types";
import type SxSWRedemptionState from "./types";
import { SxSWRedemptionResult, SxSWRedemptionStatus } from "./types";

function* checkCodeSaga(code: string) {
  const response: SxSWBlanko = yield call(getSxswRedemptionByCode, code);

  yield put(setStatus(response.redemptionStatus as any));
  if (response.redemptionStatus === SxSWRedemptionStatus.Available) {
    yield put(queueClaim(response));

    const { queuedRedemptions }: SxSWRedemptionState = yield select(
      (store: StoreState) => store.sxswRedemption,
    );
    yield put(
      redeemBlankos(queuedRedemptions?.map((x) => x.redemptionCode) as any),
    );
  }
}

function* redeemBlankosSaga(redemptionCodes: string[]) {
  const { retries, queuedRedemptions }: SxSWRedemptionState = yield select(
    (store: StoreState) => store.sxswRedemption,
  );

  const response: SxSWRedemptionResult = yield call(
    redeemSxswItems,
    redemptionCodes,
  );

  if (response === SxSWRedemptionResult.Success) {
    yield put(setRedeemed(queuedRedemptions as any));
    yield put(clearQueue());
  }

  if (response === SxSWRedemptionResult.NotReady && retries < 20) {
    yield put(redeemBlankosRetry(redemptionCodes));
  } else {
    yield put(setRedemptionResult(response));
  }
}

function* redeemBlankosRetrySaga(redemptionCodes: string[]) {
  yield delay(2500);
  yield put(redeemBlankos(redemptionCodes));
}

function* watchCheckCodeSaga() {
  while (true) {
    const { payload }: ReduxAction<string> = yield take(
      sxswRedemptionActions.CHECK_CODE,
    );
    yield call(checkCodeSaga as any, payload);
  }
}

function* watchRedeemBlankosSaga() {
  while (true) {
    const { payload }: ReduxAction<string[]> = yield take(
      sxswRedemptionActions.REDEEM_BLANKOS,
    );
    yield call(redeemBlankosSaga as any, payload);
  }
}

function* watchRedeemBlankosRetrySaga() {
  while (true) {
    const { payload }: ReduxAction<string[]> = yield take(
      sxswRedemptionActions.REDEEM_BLANKOS_RETRY,
    );
    yield call(redeemBlankosRetrySaga as any, payload);
  }
}

export default function* sxswRedemptionSagas() {
  yield all([
    fork(watchCheckCodeSaga),
    fork(watchRedeemBlankosSaga),
    fork(watchRedeemBlankosRetrySaga),
  ]);
}
