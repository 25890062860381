// Use this enum when routing to other pages so routes are always consistent. If routes are inconsistent, we will see both /register and /register/ in Google Analytics and otherwise

const Routes = {
  Index: "/",

  Account: "/account",

  Checkout: "/checkout",
  CheckoutSuccess: "/checkout/success",
  Creators: "/creators",

  Download: "/download",
  DownloadSuccess: "/download/success",

  Faqs: "/faqs",

  Register: "/register",
  RegisterSuccess: "/register/success",
  RegisterSuccessLauncher: "/register/success?source=launcher",

  Shop: "/shop",
  ShopItem: (itemId: string) => `/shop/item/${itemId}`,

  TermsOfUse: "/termsofuse",
};

export default Routes;
