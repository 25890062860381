import _ from "lodash";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { useShop } from "./checkout/shop/useShop";

/** Retrieve the custom data for an item.
 *
 * @description The advantage of having this in a useQuery hook is that error handling can be caught in the same manner as all other async code.
 */
export const useItemCustomData = (itemId: string) => {
  const { data: shop, isSuccess } = useShop();

  const item = useMemo(() => {
    return _.find(shop?.items, { itemId });
  }, [itemId, shop?.items]);
  return useQuery(
    ["useItemCustomData", item],
    () => {
      if (item === undefined) {
        throw new Error(
          `useItemCustomData: ItemId(${itemId}) was not found in shop items`,
        );
      }
      return item.customData;
    },
    {
      staleTime: Infinity,
      enabled: isSuccess && (shop?.items ?? []).length > 0,
    },
  );
};
