import { nanoid } from "nanoid";
import Script from "next/script";
import React, { useEffect, useMemo } from "react";
import TagManager from "react-gtm-module";
import config from "src/config";

/** @todo Build a better logging system */
const onError = (scriptName: string) => (e: unknown) =>
  // eslint-disable-next-line no-console
  console.error(`${scriptName} failed to load`, e);

/** See https://developers.google.com/optimize */
const AntiFlickerOptimizeScript: React.FC = () => (
  <Script
    id="opt-async-hide-script"
    strategy="beforeInteractive"
    dangerouslySetInnerHTML={{
      __html: `
  (function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
  h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
  (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
  })(window,document.documentElement,'async-hide','dataLayer',4000,
  {'${config.gtmId}':true});
`,
    }}
    onError={onError("AntiFlickerOptimize")}
  />
);

const ReCaptchaScript: React.FC = () => (
  <Script
    id="recaptcha"
    src="https://www.google.com/recaptcha/api.js?render=6LeOmKkZAAAAAMc72bzngYXK1zSifLlyb5LIoZNO"
    onError={onError("ReCaptcha")}
  />
);

const KountScript: React.FC = () => {
  const id = useMemo(() => nanoid(32), []);
  return (
    <Script
      type="text/javascript"
      src={`https://${config.kountUrl}/collect/sdk?m=${config.kountMerchantId}&s=${id}`}
      onLoad={() => {
        global?.window?.sessionStorage?.setItem?.("kountSessionId", id);
      }}
      onError={onError("Kount")}
    />
  );
};
export const ThirdPartyScripts: React.FC = () => {
  // GoogleTagManager
  useEffect(() => {
    TagManager.initialize({ gtmId: config.gtmId });
  }, []);

  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtm.js?id=${config.gtmId}`}
      />
      <AntiFlickerOptimizeScript />
      <KountScript />
      <ReCaptchaScript />
      <Script
        id="optimizely"
        src="https://cdn.optimizely.com/js/21097901207.js"
      />
    </>
  );
};
