import type { KeycloakInstance } from "keycloak-js";
import type { ReduxAction, ResponseStatus } from "../../types";
import type { Registration, SetMythicalInfoType } from "./types";

export const authActions = {
  SET_KEYCLOAK: "@auth/SET_KEYCLOAK",
  SET_TOKEN: "@auth/SET_TOKEN", // DO NOT REMOVE - used by checkout page for manually setting token from game client
  REQ_LOGIN: "@auth/REQ_LOGIN",
  REQ_LOGOUT: "@auth/REQ_LOGOUT",

  CREATE_ACCOUNT: "@auth/CREATE_ACCOUNT",
  CREATE_ACCOUNT_SUCCESS: "@auth/CREATE_ACCOUNT_SUCCESS",
  CREATE_ACCOUNT_FAILURE: "@auth/CREATE_ACCOUNT_FAILURE",
  CLEAR_CREATE_ACCOUNT_STATUS: "@auth/CLEAR_CREATE_ACCOUNT_STATUS",
};

export const setKeycloak = (keycloak: KeycloakInstance) => ({
  type: authActions.SET_KEYCLOAK,
  keycloak,
});

export const setToken = (token: string): ReduxAction<string> => ({
  type: authActions.SET_TOKEN,
  payload: token,
});

export const reqLogin = () => ({
  type: authActions.REQ_LOGIN,
});

export const reqLogout = () => ({
  type: authActions.REQ_LOGOUT,
});

export const createAccount = (
  registration: Registration,
): ReduxAction<Registration> => ({
  type: authActions.CREATE_ACCOUNT,
  payload: registration,
});

export const createAccountSuccess = (
  mythicalInfo: SetMythicalInfoType,
): ReduxAction<SetMythicalInfoType> => ({
  type: authActions.CREATE_ACCOUNT_SUCCESS,
  payload: mythicalInfo,
});

export const createAccountFailure = (
  res: ResponseStatus,
): ReduxAction<ResponseStatus> => ({
  type: authActions.CREATE_ACCOUNT_FAILURE,
  payload: res,
});

export const clearCreateAccountStatus = () => ({
  type: authActions.CLEAR_CREATE_ACCOUNT_STATUS,
});

export default authActions;
