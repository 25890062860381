export default interface EmailRedemptionState {
  redemptionStatus?: EmailRedemptionStatus;
  redemptionResult?: EmailRedemptionResult;
  code?: string;
  retries: number;
  redemptionData?: {
    redeemed: boolean;
    displayName: string;
    imageUrl: string;
    fromEntitlements: boolean;
  }[];
}

export enum EmailRedemptionStatus {
  Unchecked = "UNCHECKED",
  Available = "AVAILABLE",
  Redeemed = "REDEEMED",
  RedeemedFromEntitlements = "REDEEMED_FROM_ENTITLEMENTS",
  NoItems = "NO_ITEMS",
  Error = "ERROR",
}

export enum EmailRedemptionResult {
  Success = "SUCCESS",
  Failure = "FAILURE",
  Pending = "PENDING",
}
