import { Button, TextField, Tooltip } from "@material-ui/core";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { Add, Delete, Remove } from "@material-ui/icons";
import { noop } from "lodash";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";

interface Props {
  /** If provided, will overwrite the default Add to Cart appearance of the button */
  children?: React.ReactNode;
  disabled?: boolean;
  initialQuantity: number;
  maxQuantity?: number;
  minQuantity?: number;
  size?: "small" | "medium" | "large";
  /** If provided, will overwrite the default Tooltip behavior. */
  tooltipContent?: React.ReactNode;
  onQuantityChange?: (quantity: number, changeAmount: number) => void;
}

type TextFieldFormatterComponent = Exclude<
  React.ComponentProps<typeof TextField>["InputProps"],
  undefined
>["inputComponent"];

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    boxSizing: "border-box",
  },
  large: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1),
    color: theme.palette.common.black,
    borderRadius: "14px",
    "& $button": {
      height: "51px",
      width: "51px",
      color: theme.palette.common.black,
      borderRadius: "10px",
      fontSize: "36px",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.4)",
      },
      "&:disabled": {
        background: theme.palette.common.white,
      },
    },
    "& $quantity": {
      "& input": {
        color: theme.palette.common.black,
        fontSize: "24px",
        fontWeight: "bold",
        width: "4em",
      },
    },
  },
  medium: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0.5),
    color: theme.palette.common.black,
    borderRadius: "14px",
    "& $button": {
      height: "36px",
      width: "30px",
      color: theme.palette.common.black,
      borderRadius: "10px",
      fontSize: "18px",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.4)",
      },
      "&:disabled": {
        background: theme.palette.common.white,
      },
    },
    "& $quantity": {
      margin: 0,
      "& input": {
        color: theme.palette.common.black,
        fontSize: "16px",
        fontWeight: "bold",
        width: "3em",
      },
    },
  },
  small: {
    width: "120px",
    padding: "0 6px",
    border: "2px solid #fff",
    borderRadius: "20px",
    height: "35px",
    "& $button": {
      height: "25px",
      width: "25px",
      borderRadius: "50%",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.6)",
      },
      "& > *": {
        transform: "scale(.8, .8)",
      },
    },
    "& $quantity": {
      "& input": {
        fontFamily: theme.typography.h1.fontFamily,
        fontSize: "22px",
        width: "4em",
      },
      "& .MuiInput-underline::before": {
        display: "none",
      },
    },
  },
  button: {
    "&:hover": {
      textDecoration: "none",
    },
    "&:disabled": {
      color: "rgba(0, 0, 0, 0.5)",
    },
  },
  quantity: {
    "& input": {
      textAlign: "center",
    },
  },
  tooltip: {
    fontSize: "14px",
    textAlign: "center",
  },
}));

const QuantitySelector: React.FC<Props> = ({
  children = "Add to Cart",
  disabled = false,
  initialQuantity = 0,
  maxQuantity = 9999,
  minQuantity = 0,
  size = "large",
  tooltipContent,
  onQuantityChange = noop,
}) => {
  const classes = useStyles();

  const [quantity, setQuantity] = useState(initialQuantity);

  useEffect(() => {
    setQuantity(initialQuantity);
  }, [initialQuantity]);

  const incrementQuantityBy = (amount: number) => {
    const newQuantity = quantity + amount;

    setQuantity(newQuantity);
    onQuantityChange(newQuantity, amount);
  };

  const quantitySet = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    if (value === "") return;

    const newQuantity = parseInt(value);
    if (newQuantity < minQuantity || newQuantity > maxQuantity) return;

    onQuantityChange(newQuantity, newQuantity - quantity);
    setQuantity(newQuantity);
  };

  const QuantityButton = () => (
    <Button
      variant="contained"
      color="secondary"
      size={size}
      fullWidth
      onClick={() => incrementQuantityBy(1)}
      disabled={disabled}
    >
      {children}
    </Button>
  );

  if (quantity === 0) {
    return tooltipContent && disabled ? (
      <Tooltip
        title={tooltipContent}
        arrow
        placement="right"
        classes={{
          tooltip: classes.tooltip,
        }}
        disableFocusListener={!disabled}
        disableHoverListener={!disabled}
        disableTouchListener={!disabled}
      >
        <div>
          <QuantityButton />
        </div>
      </Tooltip>
    ) : (
      <QuantityButton />
    );
  }

  return (
    <div className={`${classes.root} ${classes[size]}`}>
      <Button
        className={classes.button}
        onClick={() => incrementQuantityBy(-1)}
        title="Remove item from cart"
      >
        {quantity <= minQuantity ? (
          <Delete fontSize="small" />
        ) : (
          <Remove fontSize={size} />
        )}
      </Button>

      <TextField
        value={quantity}
        onChange={quantitySet}
        classes={{
          root: classes.quantity,
        }}
        InputProps={{
          inputComponent: QuantityNumberFormat,
          inputProps: {
            min: minQuantity,
            max: maxQuantity,
            "data-testid": "quantity",
          },
        }}
      />

      <Button
        className={classes.button}
        onClick={() => incrementQuantityBy(1)}
        disabled={!!maxQuantity && quantity >= maxQuantity}
        title="Add item to cart"
      >
        <Add fontSize={size} />
      </Button>
    </div>
  );
};

const QuantityNumberFormat: TextFieldFormatterComponent = ({
  inputRef,
  defaultValue: wideDefaultValue,
  ...other
}) => {
  const defaultValue = Array.isArray(wideDefaultValue)
    ? wideDefaultValue[0]
    : wideDefaultValue;
  return (
    <NumberFormat
      getInputRef={inputRef}
      isNumericString
      thousandSeparator
      allowNegative={false}
      defaultValue={defaultValue}
      {...other}
    />
  );
};

export default QuantitySelector;
