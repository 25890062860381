import _ from "lodash";
import config from "src/config";
import api from "../client";
import type * as Contracts from "./contracts";
import type * as Models from "./models";

/** Get the customData and include a default gradient for the background if one isn't available
 *
 * @todo This comes back improperly typed from the backend; fix
 */
const parseCustomData = (contract: unknown): Models.ItemCustomData => {
  return {
    ...(typeof contract === "object" ? contract : {}),
    BackgroundGradient: {
      Start: _.get(contract, "BackgroundGradient.Start", "#943462"),
      End: _.get(contract, "BackgroundGradient.End", "#6100FF"),
    },
  };
};

const parseItem = (
  contract: Contracts.ClientShopItemContract,
): Models.ClientShopItem => {
  return {
    ...contract,

    // consider null to be no limit instead
    cartLimit: contract.cartLimit !== 0 ? contract.cartLimit : null,
    customData: parseCustomData(contract.customData),
    // consider null to be no limit instead
    maxSupply: contract.maxSupply !== 0 ? contract.maxSupply : null,
    catalogItem: {
      ...contract.catalogItem,
      customData: contract.catalogItem.customData ?? null,
      itemBundleContents: contract.catalogItem.itemBundleContents ?? null,
      itemContainerContents: contract.catalogItem.itemContainerContents ?? null,
    },
  };
};

const parseShop = (
  contract: Contracts.ClientShopContract,
): Models.ClientShop => {
  const items = contract.items ?? [];
  return {
    ...contract,
    items: _.map(items, parseItem),
  };
};

export const getShop = async (shopName: string): Promise<Models.ClientShop> => {
  const res = await api.get<Contracts.ClientShopContract>(
    `${config.apiUrl}/client/shops/${shopName}`,
  );
  if (res.status !== 200) {
    throw new Error(`Invalid status on getting shop: ${res.status}`);
  }

  return parseShop(res.data);
};

export * from "./models";
