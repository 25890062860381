import type { CloudflareImageProps } from "..";

const normalizeSrc = (src: string) => {
  return src[0] === "/" ? src.slice(1) : src;
};

export const transformImageSrc = ({
  src,
  width,
  height,
  format = "auto",
  quality = 90,
}: CloudflareImageProps): string => {
  if (origin.includes("localhost")) {
    return src;
  }

  const params = [];

  // format=auto will default to webp and use another format if it's smaller,
  // sometimes png are smaller than webp believe it or not
  params.push(`format=${format}`);

  // Quality difference is hardly noticeable between 90-100, so we default this to 90
  params.push(`quality=${quality}`);

  params.push("fit=scale-down");

  // If the image has a static width set, have Cloudflare resize it
  if (width) {
    params.push(`width=${width}`);
  }

  // If the image has a static height set, have Cloudflare resize it
  if (height) {
    params.push(`height=${height}`);
  }

  const paramsString = params.join(",");
  return `/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`;
};
