import _ from "lodash";
import { useCallback } from "react";
import type {
  ClientShop,
  ClientShopItem,
  ClientShopTabItem,
} from "src/services/bbp-backend/shop";
import { useShop } from "./checkout/shop/useShop";

export interface ItemData {
  item?: ClientShopItem;
  tabData?: ClientShopTabItem;
}

const getItemData = (itemId: string, shop?: ClientShop): ItemData => {
  if (shop === undefined) return {};

  const item = _.find(shop?.items, { itemId });

  const tabData = _.flatMap(shop?.tabs.map((x) => x.items)).find(
    (x) => x.itemId === itemId,
  );

  return {
    item,
    tabData,
  };
};

/** Retrieve item body from items and tab data for the item from item tabs. */
export const useItemData = (itemId: string): ItemData => {
  const { data: shop } = useShop();
  return getItemData(itemId, shop);
};

/** Retrieve item body from items and tab data for each item from item tabs */
export const useItemsData = (itemIds: string[]): ItemData[] => {
  const { data: shop } = useShop();
  const getData = useCallback(
    (itemId: string) => getItemData(itemId, shop),
    [shop],
  );
  return _.map(itemIds, getData);
};
