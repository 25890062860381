import { sum } from "lodash";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Button, Divider, Grid, Slide } from "@material-ui/core";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Routes from "../../routes";
import CartSummary from "./CartSummary";
import { useCart } from "src/hooks/checkout/shop/useCart";
import { useCartTotals } from "src/hooks/checkout/shop/useCartTotals";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    background: "#35373B",
    fontSize: "20px",
    position: "fixed",
    right: "0",
    width: "440px",
    borderTopLeftRadius: "14px",
    padding: theme.spacing(1, 2),
    boxSizing: "border-box",
    zIndex: 1400,
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      borderTopRightRadius: "14px",
    },
  },
  cart: {
    height: "150px",
    bottom: "0",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  cartItemsBox: {
    paddingBottom: "20px",
    bottom: "130px",
  },
  cartText: {
    paddingBottom: "16px",
  },
  cartArrow: {
    borderRight: "1px solid #8F9191",
    borderRadius: "0",
    padding: "0 32px 0 24px",
    height: "50px",
    width: "50px",
  },
  cartArrowIcon: {
    transition: "all .2s ease-in-out",
  },
  subtotalTitle: {
    color: "#c1c1c1",
  },
  subtotal: {
    textAlign: "right",
    fontWeight: "bold",
  },
  checkoutButton: {
    background: theme.palette.success.main,
    color: "#fff",
    padding: "8px",
    fontSize: "20px",
    "&:hover": {
      background: theme.palette.success.dark,
    },
  },
}));

const CartPopup = () => {
  const router = useRouter();
  const classes = useStyles();

  const quantity = useCart((store) =>
    sum(store.items.map((item) => item.quantity)),
  );
  const { subtotal } = useCartTotals();

  const [cartItemsDisplayed, setCartItemsDisplayed] = useState(false);
  const [cartDisplayed, setCartDisplayed] = useState(false);

  useEffect(() => {
    const hasItems = quantity !== 0 || false;

    if (hasItems === false) {
      setCartItemsDisplayed(false);
    }

    setCartDisplayed(hasItems);
  }, [quantity]);

  const onCheckoutClick = async () => {
    router.push(Routes.Checkout);
  };

  if (
    router.pathname.includes("checkout") &&
    !router.pathname.includes("checkout/success")
  ) {
    return <></>;
  }

  return (
    <>
      <Slide direction="up" in={cartItemsDisplayed} mountOnEnter unmountOnExit>
        <div className={`${classes.box} ${classes.cartItemsBox}`}>
          <CartSummary quantitySelector />

          <Divider />
        </div>
      </Slide>

      <Slide direction="up" in={cartDisplayed} mountOnEnter unmountOnExit>
        <div className={`${classes.box} ${classes.cart}`}>
          <Grid container alignItems="center" className={classes.cartText}>
            <Grid item xs={3}>
              <Button
                variant="text"
                className={classes.cartArrow}
                onClick={() => setCartItemsDisplayed(!cartItemsDisplayed)}
              >
                <KeyboardArrowDownIcon
                  className={classes.cartArrowIcon}
                  style={
                    cartItemsDisplayed ? { transform: "rotate(-180deg)" } : {}
                  }
                />
              </Button>
            </Grid>
            <Grid item xs={5}>
              <div className={classes.subtotalTitle}>Subtotal</div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.subtotal}>
                <NumberFormat
                  value={subtotal}
                  prefix="$"
                  displayType="text"
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </div>
            </Grid>
          </Grid>

          <Button
            variant="contained"
            fullWidth
            className={classes.checkoutButton}
            onClick={onCheckoutClick}
          >
            Checkout ({quantity} item{quantity > 1 ? "s" : null})
          </Button>
        </div>
      </Slide>
    </>
  );
};

export default CartPopup;
