import type { ReduxAction } from "../../../types";
import { emailRedemptionActions } from "./actions";
import type EmailRedemptionState from "./types";
import { EmailRedemptionResult, EmailRedemptionStatus } from "./types";

const initialState: EmailRedemptionState = {
  redemptionStatus: EmailRedemptionStatus.Unchecked,
  redemptionResult: EmailRedemptionResult.Pending,
  retries: 0,
  code: "",
};

export default function Reducer(
  state: EmailRedemptionState = initialState,
  action: ReduxAction<any>,
): EmailRedemptionState {
  const payload = action.payload;

  switch (action.type) {
    case emailRedemptionActions.REDEEM_RETRY:
      return {
        ...state,
        retries: state.retries + 1,
      };

    case emailRedemptionActions.SET_REDEMPTION_STATUS:
      return {
        ...state,
        redemptionStatus: payload,
      };

    case emailRedemptionActions.SET_REDEMPTION_DATA:
      return {
        ...state,
        redemptionData: payload,
      };

    case emailRedemptionActions.SET_REDEMPTION_RESULT:
      return {
        ...state,
        redemptionResult: payload,
      };

    default:
      return state;
  }
}
