import type { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import fp from "lodash/fp";
import config from "../../config";
import type { ResponseStatus } from "../../store";
import { EmailRedemptionStatus } from "../../store/modules/redemption/email/types";
import api from "../bbp-backend/client";
import { logger } from "../logger-api";
import type { RedemptionItem } from "./types";

const { apiUrl } = config;

const getBaseApiUrl = (): string => {
  return apiUrl;
};

export const getRedemptionStatusByAccount =
  async (): Promise<ResponseStatus> => {
    return await getRedemptionStatus(
      async () => await api.get(`${getBaseApiUrl()}/client/redemption/account`),
    );
  };

const getRedemptionStatus = async (
  checkRedemptionStatusDelegate: () => Promise<AxiosResponse>,
): Promise<any> => {
  try {
    const response = await checkRedemptionStatusDelegate();

    if (response.status === 202) {
      if (response.data.result?.some((item: any) => item.fromEntitlements)) {
        return {
          success: true,
          data: {
            status: EmailRedemptionStatus.RedeemedFromEntitlements,
            redemptionData: response.data.result,
          },
        };
      }
      return {
        success: true,
        data: {
          status: response.data.result?.some(
            (item: { redeemed: boolean }) => !item.redeemed,
          )
            ? EmailRedemptionStatus.Available
            : EmailRedemptionStatus.Redeemed,
          redemptionData: response.data.result,
        },
      };
    }
  } catch (ex: any) {
    if (ex.response.status === 404) {
      return {
        success: true,
        data: {
          status: EmailRedemptionStatus.NoItems,
        },
      };
    }
    logger.error(ex);

    return {
      success: false,
    };
  }
};

export const redeemItemsByAccount = async (): Promise<number> => {
  try {
    const response = await api.put(
      `${getBaseApiUrl()}/client/redemption/email`,
    );
    return response.status;
  } catch (ex: any) {
    return ex.response.status;
  }
};

/** If the campaign returns a 202 status, return true. Otherwise, false. */
export const isActiveCampaign = (campaign: string, code?: string) => {
  const type = code != null ? "DOTCOMCODE" : "DOTCOM";

  return api
    .get(`${apiUrl}/client/redemption/campaign/${campaign}/${type}/check`)
    .then(fp.matches({ status: 202 }))
    .catch(
      /** @todo Remove this. It's awful, but backend made a decision that 404 is "campaign not active" rather than "endpoint not found" :^) */
      (err: Error | AxiosError) => {
        if (!api.isAxiosError(err)) return false;
        if (err.response?.status === 404) return false;
        throw err;
      },
    );
};

enum ClaimResponseCode {
  ItemsToRedeem = 202,
  InvalidCampaign = 400,
  NoItemsToRedeem = 404,
  AlreadyUsed = 409,
  UserNotFinalized = 425,
}

/** Claim items from a campaign */
export const claimCampaignItems = (
  campaign: string,
  code?: string,
  xMythicalId?: string,
): Promise<RedemptionItem[]> => {
  const url = code
    ? `${apiUrl}/client/redemption/campaign/${campaign}/${code}`
    : `${apiUrl}/client/redemption/campaign/${campaign}`;
  return api
    .get<RedemptionItem[]>(url, {
      headers:
        xMythicalId != null ? { "X-Mythical-ID": xMythicalId } : undefined,
    })
    .catch((err: Error | AxiosError) => {
      // propagate UserNotFinalized for retry logic and AlreadyUsed for display logic
      if (
        api.isAxiosError(err) &&
        [
          ClaimResponseCode.InvalidCampaign,
          ClaimResponseCode.AlreadyUsed,
          ClaimResponseCode.UserNotFinalized,
        ].includes(Number(err.response?.status))
      )
        throw err;
      return [];
    })
    .then(_.flow(fp.get("data"), fp.defaultTo([])));
};
