import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import config from "src/config";

const KountScript: React.FC = () => {
  const [kountSessionId, setKountSessionId] = useState("");

  useEffect(() => {
    const id = nanoid(32);
    // retrieved in useKount()
    window.sessionStorage.setItem("kountSessionId", id);
    setKountSessionId(id);
  }, []);

  /* Kount script https://kount.github.io/docs/session-id/ */
  return (
    <script
      type="text/javascript"
      src={`https://${config.kountUrl}/collect/sdk?m=${config.kountMerchantId}&s=${kountSessionId}`}
      defer
    />
  );
};

export default KountScript;
