import { includes } from "lodash";

const REDIRECT_WHITELIST = [
  "mythical.market", // Production
  "marketplace.blankos-qa.iviengine.com", // QA
  "marketplace.mythical-qa.iviengine.com", // QA 2 (Stripe)
  "localhost", // Development
];

const isInBrowser = (): boolean => {
  return typeof window !== "undefined";
};

const getUrlParams = (): URLSearchParams => {
  if (!isInBrowser()) return null as any;

  const queryString = window.location.search;
  return new URLSearchParams(queryString);
};

const getUrlParam = (key: string): string => {
  const urlParams = getUrlParams();
  return urlParams?.get(key) as string;
};

const getSource = (): string => getUrlParam("source");

const getRedirectUrl = (): URL | null => {
  const urlParams = getUrlParams();

  try {
    const redirectUrl = new URL(urlParams?.get("redirect_url") as any);

    if (includes(REDIRECT_WHITELIST, redirectUrl?.hostname)) {
      return redirectUrl;
    }
  } catch {
    // Do nothing
  }

  return null;
};

const getStoreCatalog = (): string | null => getUrlParam("catalog");

/** ?source=game is considered any embedded view, including in the launcher. */
const isInGame = (): boolean => {
  if (!isInBrowser()) return false;

  const source = getSource();
  return source === "game";
};

const isFromLauncher = (): boolean => {
  if (!isInBrowser()) return false;

  const source = getSource();
  return source === "launcher";
};

const isInLauncher = isFromLauncher;

const isFromMarketplace = (): boolean => {
  if (!isInBrowser()) return false;

  const source = getSource();
  return source === "marketplace";
};

export const windowHelpers = {
  isInBrowser,
  getUrlParams,
  getUrlParam,
  getSource,
  getRedirectUrl,
  getStoreCatalog,
  isInGame,
  isInLauncher,
  isFromLauncher,
  isFromMarketplace,
};
