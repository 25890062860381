import { makeStyles } from "@material-ui/core/styles";
import Head from "next/head";
import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import CartPopup from "../components/checkout/CartPopup";
import CookieBanner from "../components/cookies/CookieBanner";
import Footer from "../components/Footer";
import Header from "../components/Header";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  main: {
    flex: "2",
  },
});

const BrowserLayout: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Head>
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
        </Head>

        <CookieBanner />

        <Header />

        <main className={classes.main}>{children}</main>

        <Footer />
      </div>

      <CartPopup />
    </>
  );
};

export default BrowserLayout;
