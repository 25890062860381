import { constant } from "lodash";
import config from "../../config";
import type { ResponseStatus } from "../../store";
import type { Registration } from "../../store/modules/auth";
import { analytics } from "../analytics-api";
import { logger } from "../logger-api";
import api from "./client";
import * as rmt from "./rmt";
import type { GetLocalitiesResponse } from "./types";

const { apiUrl } = config;

const getLocalities = async (): Promise<GetLocalitiesResponse> => {
  const response = await api.get(`${apiUrl}/client/store/localities`);

  return response.data;
};

const validateDisplayName = async (displayName: string | undefined) => {
  if (!displayName) {
    return {
      valid: false,
      message: "Required",
    };
  }
  const displayNameMatchesRegex = /^[0-9a-zA-Z]{3,16}$/.test(displayName);

  if (!displayNameMatchesRegex) {
    return {
      valid: false,
      message:
        "Must be between 3 and 16 alphanumeric characters (ex. 123abc) and contain no spaces, punctuations, special characters, or emojis.",
    };
  } else {
    try {
      const res = await api.post(
        `${apiUrl}/account/preregistration/displayname/check`,
        { displayName },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      const data = res.data;

      if (data.outcome === "accept") {
        return {
          valid: true,
        };
      } else if (data.outcome === "reject") {
        switch (data.rejectReasons[0]) {
          case "uniqueness":
            //Fire GTM event for taken display name
            analytics.snowplowTrack({
              id: analytics.Events.SIGNUP_ERROR,
              data: { errorType: "NICKNAME_EXISTS" },
            });

            return {
              valid: false,
              message: "Sorry, that username is already taken",
            };

          case "cleanspeak":
            //Fire GTM event for potty mouths
            analytics.snowplowTrack({
              id: analytics.Events.SIGNUP_ERROR,
              data: { errorType: "NICKNAME_BLACKLISTED_WORDS" },
            });

            return {
              valid: false,
              message: "Name contains blacklisted words",
            };

          default:
            console.warn("Rejected for ", data.rejectReasons[0]);
        }
      }
    } catch (error) {
      logger.handleException(error);
      //Fire GTM event for cleanspeak errors
      analytics.snowplowTrack({
        id: analytics.Events.SIGNUP_ERROR,
        data: {
          errorType: "CLEANSPEAK_ERROR",
          // responseCode: data.status // TODO: Uhh... where did data.status come from in the past?
        },
      });

      return {
        valid: false,
        message:
          "Application error checking display name, please try again later",
      };
    }
  }
};

export const createAccount = async (
  registration: Registration,
): Promise<ResponseStatus> => {
  const handleResponse = (res: any) => {
    switch (res.status) {
      case 200:
        return {
          success: true,
          data: res.data,
        };

      case 409:
        return {
          success: false,
          message:
            "Sorry, that email is already registered. If you need assistance ",
        };

      default:
        return {
          success: false,
          message:
            "We hit a snag while trying to  create your account. Please try again, or ",
        };
    }
  };

  try {
    const res = await api.post(
      `${apiUrl}/account/preregistration`,
      registration,
    );
    return handleResponse(res);
  } catch (error: any) {
    logger.handleException(error);
    return handleResponse(error.response);
  }
};

/**
 * Attempt to link Blankos account to a Twitch account.
 * @param linkCode The code provided by Twitch for linking accounts.
 * @param xMythicalId When a new account is created, they don't have their auth in axios; it will be necessary to provide it here instead.
 * @returns An object with a property success:true if the linking was successful or success:false if it encountered an error of any kind.
 */
const linkTwitchAccount = async (
  linkCode: string,
  xMythicalId?: string,
): Promise<{
  success: boolean;
  status: any;
}> => {
  const handleResponse = (res: any) => {
    switch (res.status) {
      case 200:
        return {
          success: true,
          status: res.status,
        };

      case 409:
        return {
          success: false,
          status: res.status,
        };

      default:
        return {
          success: false,
          status: res.status,
        };
    }
  };

  try {
    const res = await api.get(
      `${apiUrl}/client/linking/twitch?code=${linkCode}`,
      {
        headers: xMythicalId ? { "X-Mythical-ID": xMythicalId } : undefined,
      },
    );
    return handleResponse(res);
  } catch (error: any) {
    logger.handleException(error);
    return handleResponse(error.response);
  }
};

/** Send an email to the account holder with a link to change their password. */
const sendPasswordUpdateEmail = async (): Promise<{ success: boolean }> =>
  await api
    .put(`${apiUrl}/account/management/reset-password-email`)
    .then(constant({ success: true }))
    .catch(constant({ success: false }));

export const MythicalApi = {
  getLocalities,
  linkTwitchAccount,
  validateDisplayName,
  sendPasswordUpdateEmail,
  ...rmt,
};
