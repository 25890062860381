import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import {
  getRedemptionStatusByAccount,
  redeemItemsByAccount,
} from "../../../../services/redemption/email-redemption-api";
import type StoreState from "../../../index";
import type { ResponseStatus } from "../../../types";
import {
  emailRedemptionActions,
  redeem,
  redeemRetry,
  setEmailRedemptionData,
  setEmailRedemptionResult,
  setEmailRedemptionStatus,
} from "./actions";
import type EmailRedemptionState from "./types";
import { EmailRedemptionResult, EmailRedemptionStatus } from "./types";

function* checkAccountSaga() {
  const response: ResponseStatus = yield call(getRedemptionStatusByAccount);
  const status: EmailRedemptionStatus = response.data.status;

  yield put(setEmailRedemptionStatus(status));
  yield put(setEmailRedemptionData(response.data.redemptionData));

  if (status === EmailRedemptionStatus.Available) {
    yield put(redeem());
  }

  if (status === EmailRedemptionStatus.RedeemedFromEntitlements) {
    yield put(setEmailRedemptionResult(EmailRedemptionResult.Success));
  }
}

function* redeemSaga() {
  const { retries }: EmailRedemptionState = yield select(
    (store: StoreState) => store.emailRedemption,
  );

  const response: number = yield call(redeemItemsByAccount);

  if (response === 425 && retries < 20) {
    yield put(redeemRetry());
  } else {
    const result =
      response === 200
        ? EmailRedemptionResult.Success
        : EmailRedemptionResult.Failure;

    yield put(setEmailRedemptionResult(result));
  }
}

function* redeemRetrySaga() {
  yield delay(2500);
  yield put(redeem());
}

function* watchCheckAccountSaga() {
  yield takeEvery(emailRedemptionActions.CHECK_ACCOUNT, checkAccountSaga);
}

function* watchRedeemSaga() {
  yield takeEvery(emailRedemptionActions.REDEEM, redeemSaga);
}

function* watchRedeemRetrySaga() {
  yield takeEvery(emailRedemptionActions.REDEEM_RETRY, redeemRetrySaga);
}

export default function* emailRedemptionSagas() {
  yield all([
    fork(watchCheckAccountSaga),
    fork(watchRedeemSaga),
    fork(watchRedeemRetrySaga),
  ]);
}
