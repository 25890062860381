import { useEffect, useState } from "react";
import { analytics } from "src/services/analytics-api";

// Calling this v2 since the Zustand version used "cookie-preferences"
export const COOKIE_NAME = "cookie-preferences-v2";

interface UseCookiePreferencesType {
  preferences: CookiesPreferences;
  acceptAll: () => void;
  setPreferences: (preferences: CookiesPreferences) => void;
}

export interface CookiesPreferences {
  acknowledged?: boolean;
  acknowledgedTimestamp?: Date;
  accepted?: boolean;
  acceptedTimestamp?: Date;
  advanced?: {
    saleOfPersonalData: boolean;
    socialMedia: boolean;
    targeting: boolean;
    performance: boolean;
  };
}

const initialState: CookiesPreferences = {
  acknowledged: false,
  acknowledgedTimestamp: undefined,
  accepted: false,
  acceptedTimestamp: undefined,
  advanced: {
    saleOfPersonalData: false,
    socialMedia: false,
    targeting: false,
    performance: false,
  },
};

export const useCookiePreferences = (): UseCookiePreferencesType => {
  const [cookiePreferences, setCookiePreferences] =
    useState<CookiesPreferences>(initialState);

  useEffect(() => {
    const cookie = localStorage.getItem(COOKIE_NAME);
    if (!cookie) return;

    setCookiePreferences(JSON.parse(cookie));
  }, []);

  const setPreferences = (preferences: CookiesPreferences) => {
    // set the local storage
    localStorage.setItem(COOKIE_NAME, JSON.stringify(preferences));
    // send data to analytics
    analytics.snowplowTrack({ id: analytics.Events.PAGE_VIEW });
    analytics.setCookiesPreferences(preferences);
    // set hook value
    setCookiePreferences({ ...preferences });
  };

  const acceptAll = () => {
    setPreferences({
      ...cookiePreferences,
      acknowledged: true,
      acknowledgedTimestamp: new Date(),
      accepted: true,
      acceptedTimestamp: new Date(),
      advanced: {
        saleOfPersonalData: true,
        socialMedia: true,
        targeting: true,
        performance: true,
      },
    });
  };

  return {
    preferences: cookiePreferences,
    acceptAll,
    setPreferences,
  };
};
