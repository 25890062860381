const log = ({
  data,
  level = "info",
}: {
  data: any;
  level?: "debug" | "info" | "warning" | "error" | "critical";
}) => {
  switch (level) {
    case "debug":
      console.debug(data);
      break;
    case "info":
      console.info(data);
      break;
    case "warning":
      console.warn(data);
      break;
    case "error":
      console.error(data);
      break;
    case "critical":
      console.error(data);
      break;
    default:
      console.log(data);
  }
};

const critical = (e: unknown) => {
  log({ data: e, level: "critical" });
};

const error = (e: unknown) => {
  log({ data: e, level: "error" });
};

const warning = (e: unknown) => {
  log({ data: e, level: "warning" });
};

const info = (e: unknown) => {
  log({ data: e, level: "info" });
};

const debug = (e: unknown) => {
  log({ data: e, level: "debug" });
};

const handleException = debug;

export const logger = {
  log,
  critical,
  error,
  warning,
  info,
  debug,
  handleException,
};
