import type { ReduxAction } from "../../../types";
import type { EmailRedemptionResult, EmailRedemptionStatus } from "./types";

const prefix = "@redemption/email";

export const emailRedemptionActions = {
  CHECK_ACCOUNT: `${prefix}/CHECK_ACCOUNT`,
  REDEEM: `${prefix}/REDEEM`,
  REDEEM_RETRY: `${prefix}/REDEEM_RETRY`,
  SET_REDEMPTION_STATUS: `${prefix}/SET_REDEMPTION_STATUS`,
  SET_REDEMPTION_DATA: `${prefix}/SET_REDEMPTION_DATA`,
  SET_REDEMPTION_RESULT: `${prefix}/SET_REDEMPTIONS_RESULT`,
};

export const checkAccount = (): ReduxAction => ({
  type: emailRedemptionActions.CHECK_ACCOUNT,
});

export const setEmailRedemptionStatus = (
  redemptionStatus: EmailRedemptionStatus,
): ReduxAction<EmailRedemptionStatus> => ({
  type: emailRedemptionActions.SET_REDEMPTION_STATUS,
  payload: redemptionStatus,
});

export const setEmailRedemptionData = (
  redemptionData: any,
): ReduxAction<any> => ({
  type: emailRedemptionActions.SET_REDEMPTION_DATA,
  payload: redemptionData,
});

export const redeem = (): ReduxAction => ({
  type: emailRedemptionActions.REDEEM,
});

export const redeemRetry = (): ReduxAction => ({
  type: emailRedemptionActions.REDEEM_RETRY,
});

export const setEmailRedemptionResult = (
  redemptionResult: EmailRedemptionResult,
): ReduxAction<EmailRedemptionResult> => ({
  type: emailRedemptionActions.SET_REDEMPTION_RESULT,
  payload: redemptionResult,
});
