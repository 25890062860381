import type { AxiosRequestConfig } from "axios";
import _ from "lodash";
import fp from "lodash/fp";
import { windowHelpers } from "src/helpers/window-helpers";
import type { NonNullableObject } from "src/services/types";
import api, { apiUrl } from "../client";
import type {
  RmtCreateRequestContract,
  RmtPaymentMethodResponseContract,
  RmtUpholdQuoteResponseContract,
  RmtWalletResponseContract,
  TokenResponseContract,
} from "./contracts";
import type {
  CreateItemInstancesResponse,
  RmtGetPaymentMethodsResponse,
  RmtOrderRequest,
  RmtOrderResponse,
  RmtPaymentMethod,
  RmtPayRequest,
  RmtUpholdQuoteRequest,
  RmtUpholdQuoteResponse,
  RmtUpholdWallet,
  TokenResponse,
} from "./models";
import type { DeletePaymentMethodResponse } from "./types";

const rmtUri = `${apiUrl}/client/rmt`;

const nullToUndefined: <TObj>(obj: TObj) => NonNullableObject<TObj> = fp.omitBy(
  fp.isNil,
);

export const getSavedPaymentMethods = async (): Promise<RmtPaymentMethod[]> => {
  const res = await api.get<RmtGetPaymentMethodsResponse>(`${rmtUri}/payments`);

  return _.map(res.data.paymentMethods ?? [], nullToUndefined);
};

export const saveNewPaymentMethod = async (
  body: RmtCreateRequestContract,
): Promise<RmtPaymentMethod> => {
  const res = await api.post<RmtPaymentMethodResponseContract>(
    `${rmtUri}/payments`,
    body,
  );

  if (res.data.paymentMethod === undefined) {
    throw new Error("saveNewPaymentMethod encountered an unknown error.");
  }
  return nullToUndefined(res.data.paymentMethod);
};

export const startOrder = async (
  { storeId, order }: { storeId: string; order: RmtOrderRequest },
  xMythicalId?: string,
): Promise<RmtOrderResponse> => {
  const url = `${apiUrl}/client/shops/${storeId}/rmt-order`;

  const res = await api.post<RmtOrderResponse>(
    url,
    order,
    xMythicalId !== undefined
      ? { headers: { "X-Mythical-ID": xMythicalId } }
      : undefined,
  );

  return res.data;
};

export const orderPay = async (
  orderId: string,
  data: RmtPayRequest,
  xMythicalId?: string,
): Promise<CreateItemInstancesResponse> => {
  if (!windowHelpers.isInBrowser()) {
    return apiPut(orderId, data, xMythicalId);
  }

  const grecaptchaPromise = new Promise<boolean>((res, rej) => {
    try {
      window.grecaptcha.ready(() => res(true));
    } catch (e) {
      rej(e);
    }
  });

  const ready = await grecaptchaPromise;

  if (!ready) throw new Error("ReCaptcha: Unable to instantiate.");

  const recaptchaVerificationTag = await window.grecaptcha.execute(
    "6LeOmKkZAAAAAMc72bzngYXK1zSifLlyb5LIoZNO",
    {
      action: "checkout",
    },
  );
  const registrationUtmSource = window.utmSource;
  const registrationUtmCampaign = window.utmCampaign;
  const registrationOrigin = "bp-www";

  const captchaOrderData = {
    ...data,
    recaptchaVerificationTag,
    registrationUtmSource,
    registrationUtmCampaign,
    registrationOrigin,
  };

  return await apiPut(orderId, captchaOrderData, xMythicalId);
};

export const apiPut = async (
  orderId: string,
  data: RmtPayRequest,
  xMythicalId?: string,
) => {
  const url = `${apiUrl}/client/rmt/orders/${orderId}/pay`;

  const res = await api.put<CreateItemInstancesResponse>(
    url,
    data,
    xMythicalId !== undefined
      ? { headers: { "X-Mythical-ID": xMythicalId } }
      : undefined,
  );

  return res.data;
};

export const getCybersourceJwk = async (
  xMythicalId?: string,
): Promise<TokenResponse> => {
  const options: AxiosRequestConfig | undefined =
    typeof xMythicalId === "string"
      ? {
          headers: { "X-Mythical-ID": xMythicalId },
        }
      : {};

  const res = await api.get<TokenResponseContract>(
    `${apiUrl}/client/rmt/jwk`,
    options,
  );

  return res.data;
};

/** Swagger: https://gateway.dev.blankos.game/swagger-ui/index.html?configUrl=/v3/api-docs/swagger-config#/Client%20API%20-%20RMT/deletePaymentMethod */
export const deletePaymentMethod = async (paymentMethodToken: string) =>
  api.delete<DeletePaymentMethodResponse>(
    `${apiUrl}/client/rmt/payments/${paymentMethodToken}`,
  );

/**
 * Retrieve an uphold wallet instance
 */
export const getUpholdWallet = async (): Promise<RmtUpholdWallet> => {
  const res = await api.get<RmtWalletResponseContract>(
    `${rmtUri}/uphold/wallet`,
  );

  if (res.data.uphold === undefined) {
    throw new Error("getUpholdWallet: No wallet found for user");
  }

  if (res.data.uphold.status === "linked-but-unknown-error") {
    throw new Error("getUpholdWallet: Unknown error retrieving Uphold wallet.");
  }

  // this should delete any property that is null on the address
  if (res.data.uphold.address !== undefined) {
    return {
      ...res.data.uphold,
      address: nullToUndefined(res.data.uphold.address),
    };
  }

  return _.omit(res.data.uphold, "address");
};

/**
 * Given a card (UpholdCard upholdId) and a total cost, request a quote for the cost.
 *  Necessary due to conversion fees and constantly-changing value of crypto
 */
export const makeQuote = async (
  request: RmtUpholdQuoteRequest,
): Promise<RmtUpholdQuoteResponse> => {
  const res = await api.post<RmtUpholdQuoteResponseContract>(
    `${rmtUri}/uphold/quote`,
    request,
  );

  const mapToModel: (
    contract: RmtUpholdQuoteResponseContract,
  ) => RmtUpholdQuoteResponse = _.flow(
    fp.update("requestedAmount", parseFloat),
    fp.update("quotedAmount", parseFloat),
    fp.update("normalizedQuotedAmount", parseFloat),
  );

  const contract = res.data;

  return mapToModel(contract);
};
