import fp from "lodash/fp";
import type { GetState, SetState } from "zustand";
import create from "zustand";
import type { StoreApiWithPersist } from "zustand/middleware";
import { persist } from "zustand/middleware";

export interface CartItem {
  itemId: string;
  quantity: number;
}

// TODO maybe change to maps instead of arrays?
export interface UseCartType {
  items: CartItem[];
  purchases: string[];

  setItems: (items: CartItem[]) => void;
  setItem: (itemId: string, quantity: number) => void;
  clearCart: () => void;
  addPurchases: (itemIds: string[]) => void;
}

export const useCart = create<
  UseCartType,
  SetState<UseCartType>,
  GetState<UseCartType>,
  StoreApiWithPersist<UseCartType>
>(
  persist(
    (set, get) => ({
      items: [] as CartItem[],
      purchases: [] as string[],

      setItems: (items) => {
        set({ items });
      },
      setItem: (itemId, quantity) => {
        const items = get().items.filter((item) => item.itemId !== itemId);
        if (quantity > 0) {
          items.push({ itemId, quantity });
        }

        return set({ items });
      },
      clearCart: () => set({ items: [] }),
      addPurchases: (itemIds) => {
        const purchases = fp.flow(fp.concat, fp.uniq)(
          get().purchases,
          itemIds,
        ) as string[];

        return set({ purchases });
      },
    }),
    {
      name: "cart",
      version: 0,
    },
  ),
);
