import authActions from "./actions";
import type AuthState from "./types";

const initialState: AuthState = {
  initialized: false,
};

export default function Reducer(state: AuthState = initialState, action: any) {
  switch (action.type) {
    case authActions.SET_KEYCLOAK:
      return {
        ...state,
        initialized: true,
        keycloak: action.keycloak,
      };

    case authActions.SET_TOKEN:
      return {
        ...state,
        keycloak: {
          ...state.keycloak,
          token: action.payload,
        },
      };

    case authActions.CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        createAccountStatus: {
          success: true,
        },
        mythicalId: action.payload.mythicalId,
        mythicalIdHash: action.payload.mythicalIdHash,
        mythicalUsername: action.payload.mythicalUsername,
      };

    case authActions.CREATE_ACCOUNT_FAILURE:
      return {
        ...state,
        createAccountStatus: action.payload,
      };

    case authActions.CLEAR_CREATE_ACCOUNT_STATUS:
      return {
        ...state,
        createAccountStatus: undefined,
      };

    default:
      return state;
  }
}
