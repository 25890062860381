import type Keycloak from "keycloak-js";
import config from "../../config";
import { windowHelpers } from "../../helpers/window-helpers";

/**
 * Customize keycloak instance since preferred_username doesn't exist on the default keycloak instance type
 *
 * @todo Determine why this doesn't exist on the global declare.
 */
export type KeycloakInstance = Keycloak.KeycloakInstance & {
  idTokenParsed: Keycloak.KeycloakInstance["idTokenParsed"] & {
    preferred_username?: string;
  };
};

/**
 * The instance of Keycloak to be provided as an authclient.
 *
 * If we're not in a browser, this is just an empty object. Otherwise, it pulls info from the env files.
 */
const keycloak: KeycloakInstance = (() => {
  if (!windowHelpers.isInBrowser()) return {} as KeycloakInstance;

  // inline it to prevent import issues when not in a browser
  // TODO: try to do this without an inline require.
  const Keycloak: (
    config?: string | Keycloak.KeycloakConfig,
    // eslint-disable-next-line
  ) => KeycloakInstance = require("keycloak-js");

  return Keycloak({
    url: config.keycloakUrl,
    realm: config.keycloakRealm,
    clientId: config.keycloakClientId,
  });
})();

export default keycloak;
