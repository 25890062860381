import type { ReduxAction } from "../../../types";
import { sxswRedemptionActions } from "./actions";
import type { SxSWBlanko } from "./types";
import type SxSWRedemptionState from "./types";
import { SxSWRedemptionResult, SxSWRedemptionStatus } from "./types";

const initialState: SxSWRedemptionState = {
  currentCode: "",
  currentStatus: SxSWRedemptionStatus.Unchecked,
  checkedCodes: [],
  queuedRedemptions: [],
  retries: 0,
  redemptions: [],
  redemptionResult: SxSWRedemptionResult.Pending,
};

const queueClaimReducer = (state: SxSWRedemptionState, payload: SxSWBlanko) => {
  const newRedemptionCode = payload.redemptionCode;
  if (
    state.queuedRedemptions?.find(
      (o: any) => o.redemptionCode === newRedemptionCode,
    )
  ) {
    return state;
  }
  const queuedRedemptions = [...(state?.queuedRedemptions as any), payload];
  return {
    ...state,
    queuedRedemptions: queuedRedemptions,
  };
};

const saveCodeReducer = (state: SxSWRedemptionState, payload: string) => {
  if (state?.checkedCodes?.includes(payload)) {
    return state;
  }

  const savedCodes = [...(state.checkedCodes as any), payload];
  return {
    ...state,
    checkedCodes: savedCodes,
  };
};

const reducer = (
  state: SxSWRedemptionState = initialState,
  action: ReduxAction<any>,
): SxSWRedemptionState => {
  const payload = action.payload;

  switch (action.type) {
    case sxswRedemptionActions.CHECK_CODE:
      return {
        ...state,
        currentCode: payload,
      };

    case sxswRedemptionActions.SET_STATUS:
      return {
        ...state,
        currentStatus: payload,
      };

    case sxswRedemptionActions.QUEUE_CLAIM:
      return queueClaimReducer(state, payload);

    case sxswRedemptionActions.SAVE_CODE:
      return saveCodeReducer(state, payload);

    case sxswRedemptionActions.SET_REDEEMED:
      return {
        ...state,
        redemptions: payload,
      };

    case sxswRedemptionActions.CLEAR_QUEUE:
      return {
        ...state,
        currentCode: "",
        currentStatus: SxSWRedemptionStatus.Unchecked,
        queuedRedemptions: [],
      };

    case sxswRedemptionActions.REDEEM_BLANKOS_RETRY:
      return {
        ...state,
        retries: state.retries + 1,
      };

    case sxswRedemptionActions.SET_REDEMPTION_RESULT:
      return {
        ...state,
        redemptionResult: payload,
      };

    case sxswRedemptionActions.REDEEM_BLANKOS:
    default:
      return state;
  }
};

export default reducer;
