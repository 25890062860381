import create from "zustand";
import { devtools } from "zustand/middleware";

interface UseStoreIdType {
  storeId: string;
  setStoreId: (storeId: string) => void;
}

export const useStoreId = create<UseStoreIdType>(
  devtools((set) => ({
    storeId: "Dotcom",
    setStoreId: (storeId: string) => set({ storeId }),
  })),
);
