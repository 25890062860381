import { applyMiddleware, compose, createStore } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { composeWithDevTools } from "redux-devtools-extension";
import type { PersistConfig } from "redux-persist";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import * as Sentry from "@sentry/react";
import config from "../config";
import type StoreState from "./index";
import { createRootReducer, rootSaga } from "./modules";

const { enableLogRocket, enableDevtools } = config;

const persistConfig: PersistConfig<any> = {
  key: "root",
  debug: true,
  whitelist: ["cart", "preferences"],
  storage,
};

const persistedReducer = persistReducer(persistConfig, createRootReducer());

export const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];

if (enableLogRocket) {
  // TODO: try to make this behave normally; presently done to prevent it from initializing outside of proper environments.
  // eslint-disable-next-line
  const LogRocket = require("logrocket");
  middleware.push(LogRocket.reduxMiddleware());
}

const sentryEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state: StoreState): StoreState => ({
    ...state,
    auth: {
      ...state.auth,
      keycloak: null as any,
    },
  }),
});

const enhancers = [applyMiddleware(...middleware), sentryEnhancer];

export const store = createStore(
  persistedReducer,
  enableDevtools ? composeWithDevTools(...enhancers) : compose(...enhancers),
);

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

const getStore = () => store;

export default getStore;

export const createTestStore = (preloadedState?: Partial<StoreState>) =>
  preloadedState
    ? configureStore({
        reducer: persistedReducer,
        middleware: [sagaMiddleware],
        preloadedState,
      })
    : null;
