import { MythicalThemeProvider } from "@mythicalgames/blankos-ui";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { isIE } from "react-device-detect";
import { Hydrate, QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import type { AppPageProps } from "src/pages/_app";
import { useAuth } from "@/hooks/useAuth";
import initialize from "../init";
import { SessionProvider } from "next-auth/react";
import { analytics } from "../services/analytics-api";
import { persistor, store } from "../store";
import type { Session } from "next-auth";

/**
 * Keeping the useEffect isolated helps render more static content.
 * @returns null
 */
const KeycloakAnalytics: React.FC = () => {
  const { initialized, mythicalId, mythicalUsername } = useAuth();

  useEffect(() => {
    if (!initialized) return;

    initialize();

    analytics.setUser(mythicalId as any, mythicalUsername);
  }, [initialized, analytics]);

  return null;
};

/**
 * Providers wrapper for all top-level app providers.
 *
 * dehydratedState can be provided from SSG/SSR as described here: https://react-query.tanstack.com/guides/ssr
 *
 * @returns Providers needed for the application to function.
 */
const AppProviders: React.FC<AppPageProps> = ({
  children,
  dehydratedState,
  session,
}) => {
  const router = useRouter();

  const [queryClient] = useState(() => new QueryClient());

  if (router.isReady && isIE) {
    router.push("/ie11");
    return null;
  }

  return (
    <SessionProvider session={session as Session}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={dehydratedState}>
              <KeycloakAnalytics />
              <MythicalThemeProvider>{children}</MythicalThemeProvider>
            </Hydrate>
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </SessionProvider>
  );
};

export default AppProviders;
