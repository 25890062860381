import { sum } from "lodash";
import { useMemo } from "react";
import { useCart } from "./useCart";
import { useShop } from "./useShop";

export const useCartTotals = () => {
  const items = useCart((store) => store.items);
  const { data } = useShop();

  const subtotal = useMemo(() => {
    if (typeof data === "undefined") return 0;

    const prices = items.map((item) => {
      return (
        (data.items.find((x) => x.itemId === item.itemId)?.rmPrice ?? 0) *
        item.quantity
      );
    });
    return sum(prices);
  }, [data, items]);

  return { subtotal };
};
